<template>
  <div v-cloak>
    <transition appear appear-active-class="fade-enter-active" name="fade">
      <div>
        <div class="wrap">
          <h1>Реестр заполненных анкет
            <RegionAndDepartmentPicker v-model="$regionAndDepartmentInfo"></RegionAndDepartmentPicker>

            <div class="header-buttons">
              <div :class="{ active: isActive }" class="menu-button search-menu-button" @click="isActive = !isActive">
                <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M9.5 3C11.2239 3 12.8772 3.68482 14.0962 4.90381C15.3152 6.12279 16 7.77609 16 9.5C16 11.11 15.41 12.59 14.44 13.73L14.71 14H15.5L20.5 19L19 20.5L14 15.5V14.71L13.73 14.44C12.59 15.41 11.11 16 9.5 16C7.77609 16 6.12279 15.3152 4.90381 14.0962C3.68482 12.8772 3 11.2239 3 9.5C3 7.77609 3.68482 6.12279 4.90381 4.90381C6.12279 3.68482 7.77609 3 9.5 3ZM9.5 5C7 5 5 7 5 9.5C5 12 7 14 9.5 14C12 14 14 12 14 9.5C14 7 12 5 9.5 5Z"
                      fill="#0033A0"/>
                </svg>
              </div>
              <div class="search-panel-wrap">
                <div class="search-panel">
                  <div class="wrap">
                    <a class="close" @click="isActive=false">
                      <svg fill="none" height="14" viewBox="0 0 14 14" width="14" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                            fill="#0033A0"/>
                      </svg>
                    </a>
                    <h1>Поиск</h1>
                    <v-row>
                      <v-col cols="12" lg="3">
                        <v-select
                            label="Анектирование"
                            v-model="anketConfigId"
                            :items="configs"
                            item-text="title"
                            item-value="id"
                        ></v-select>
                      </v-col>

                      <v-col>
                        <DateField
                            labelName="Дата с"
                            :dateValue="beginDate"
                            @minput="beginDate=$event;"
                        ></DateField>
                      </v-col><v-col>
                        <DateField
                            labelName="Дата по"
                            :dateValue="endDate"
                            @minput="endDate=$event;"
                        ></DateField>
                      </v-col>
                    </v-row>

                    <div class="button-wrap">
                      <button class="btn btn-primary" @click="adjustFilters">применить</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </h1>
          <div v-if="!isActive" class="mb-3">
            Анкетирование: {{ anketConfigId ? configs?.find(e=>e.id===anketConfigIdAdjusted)?.title: 'все'}}
            <br>
            Дата заполнения анкеты: с {{ beginDateAdjusted | dateFormat }} по {{ endDateAdjusted | dateFormat}}
          </div>
          <v-btn
              @click="downloadReport('czn')"
              outlined
              class="mr-3"
              :disabled="loading"
          >
            Выгрузить по ЦЗН
          </v-btn>
          <v-btn
              @click="downloadReport('region')"
              outlined
              :disabled="loading"
          >
            Выгрузить по региону
          </v-btn>
          <table class="anketsRegister-table" style="width: 100%" v-if="apiLoaded">
            <thead>
            <tr>
              <th>Анкетирование</th>
              <!--              <th>Комментарий</th>-->
              <!--              <th>Результат приема</th>-->
              <th>Дата</th>
              <th>№ анкетируемого / E-mail / Имя</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="anket in anketFormDatas" :key="'sd7f'+anket.id">
              <td>
                <router-link :to="'/ankets/anketInfo/'+anket.id">
                  {{ getAnketConfig(anket.configId)?.title }}
                </router-link>
              </td>
              <td>
                {{ anket.localDateTime | dateTimeFormat }}
              </td>
              <td>
                {{ anket.regnumber }}<br>{{ anket.email }}<br>{{ anket.userName }}
              </td>
              <td>
                <a :href="anketLink(anket.configId)" target="_blank" style="text-decoration: none">
                  <v-icon class="icon-item icon-briefcase-eye-outline"></v-icon>
                </a>
              </td>
            </tr>
            <tr v-if="!lastPortionLoaded">
              <td colspan="6">
                <a href="#" @click.prevent="loadAnketFormData">Загрузить еще</a>
              </td>
            </tr>
            </tbody>
          </table>
          <div v-else style="height:1000px"></div>
        </div>
        <div v-if="errorText!=null" class="error" style="position: fixed;bottom: 0">
          Ошибка: {{ errorText }}
        </div>
      </div>
    </transition>
    <loader-dialog :show="loading" msg="Загрузка"></loader-dialog>
  </div>
</template>

<script>

import RegionAndDepartmentPicker from "@/components/RegionAndDepartmentPicker";
import DateField from "@/components/elements/DateField";
import {exportAnketsToXlsx, loadAnketConfigs, loadAnketFormData} from "@/modules/ankets";
import api from "@/modules/api";
import LoaderDialog from "@/components/elements/LoaderDialog.vue";

export default {
  name: 'AnketsRegister',
  props: {},
  components: {
    LoaderDialog,
    RegionAndDepartmentPicker,
    DateField
  },
  data() {
    return {
      domain: 'anket',
      downloadTaskId: null,
      loading: false,
      anketFormDatas: [],
      apiLoaded: false,
      errorText: null,
      configs: [],
      page: 0,
      pageSize: 100,
      lastPortionLoaded: false,
      anketConfigId: null, // это то что введено в критериях поиска (может быть до кнопки "применить")
      beginDate: null,
      endDate: null,
      anketConfigIdAdjusted: null, // adjusted - что применено
      beginDateAdjusted: null,
      endDateAdjusted: null,
      isActive: false
    };
  },
  filters: {
    dateTimeFormat: function (date) {
      if (date == null) {
        return ''
      }
      let mdate = new Date(date)
      let options = {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      }
      return mdate.toLocaleDateString('ru-RU', options)
    },

    dateFormat: function (date) {
      if (date == null) {
        return '-'
      }
      let mdate = new Date(date)
      let options = {
        timeZone: 'Europe/Moscow',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      }
      return mdate.toLocaleDateString('ru-RU', options)
    }
  },
  methods: {
    async loadAnketFormData() {
      let req = await loadAnketFormData(this.getQuerySpec())
      if (req.ok) {
        this.anketFormDatas = this.anketFormDatas.concat(req.payload)
        this.lastPortionLoaded = req.payload.length < this.pageSize
        this.page++
      } else {
        this.errorText = "Ошибка подключения к серверу или обработки запроса: " + req.error
      }
    },

    getQuerySpec(){
      let query = {}
      query.cznId = this.$regionAndDepartmentInfo.department.id
      query.configId = this.anketConfigIdAdjusted
      query.startDate = this.beginDateAdjusted
      query.endDate = this.endDateAdjusted
      query.page = this.page
      query.size = this.pageSize
      return query
    },

    async loadAnketConfigs() {
      const req = await loadAnketConfigs()
      if (req.ok) {
        this.configs = req.payload.map(config => {
          const configObject = JSON.parse(config.anketJson)
          return {
            id: config.id,
            active: config.active,
            title: configObject.title,
            description: configObject.description,
            modifiedDate: config.modifiedDate,
          }
        })
      } else {
        this.errorText = "Не удается загрузить данные с сервера"
      }
    },

    getAnketConfig(id) {
      return this.configs.find(e => e.id === id)
    },

    adjustFilters(){
      this.anketConfigIdAdjusted = this.anketConfigId
      this.beginDateAdjusted = this.beginDate
      this.endDateAdjusted = this.endDate
      this.initAnkets()
      // TODO нужно предусмотреть логику, что фильтры не сбрасываются при переходе вперед и нажатии на кнопку назад
      this.isActive = false
    },

    anketLink(anketConfigId) {
      return 'https://new.profczn.ru/' + anketConfigId + '?id=' + this.$regionAndDepartmentInfo?.department?.id
    },

    async initAnkets() {
      this.anketFormDatas = []
      this.page = 0
      await this.loadAnketFormData()
    },

    exportXlsx(){
      exportAnketsToXlsx(this.getQuerySpec(), null, this.$getDepartmentById, this.$getRegionById)
    },

    async downloadReport(type) {
      try {
        this.loading = true;

        const configParam = `?config_id=${this.anketConfigIdAdjusted}`
        let cznParam = ''
        if (type === 'czn') {
          cznParam = `&czn_id=${this.$regionAndDepartmentInfo.department.id}`
        }
        let regionParam = ''
        if (type === 'region') {
          regionParam = `&region_code=${this.$regionAndDepartmentInfo.region.code}`
        }
        let dateFromParam = ''
        if (this.beginDateAdjusted) {
          dateFromParam = `&date_from=${this.beginDateAdjusted}`
        }
        let dateToParam = ''
        if (this.endDateAdjusted) {
          dateToParam = `&date_to=${this.endDateAdjusted}`
        }

        const response = (await api.get(`/reports/generate/${this.domain}/1${configParam}${regionParam}${cznParam}${dateFromParam}${dateToParam}`)).payload;
        this.downloadTaskId = response.task_id;
        await this.checkTaskStatus();
      } catch (error) {
        console.error('Error generating report:', error);
        alert('Ошибка при генерации отчета!');
        this.loading = false;
      }
    },
    async checkTaskStatus() {
      try {
        const response = (await api.get(`/reports/status/${this.downloadTaskId}`)).payload;
        if (response.status === 'SUCCESS') {
          await this.downloadFile();
        } else {
          setTimeout(this.checkTaskStatus, 2000);
        }
      } catch (error) {
        console.error('Error checking task status:', error);
        alert('Ошибка при проверке статуса задачи!');
        this.loading = false;
      }
    },
    async downloadFile() {
      try {
        const blob = (await api.get(`/reports/download/${this.downloadTaskId}`, null, 'blob')).payload;
        this.$downloadBlob(blob, `Отчет по анкетам.xlsx`);
      } catch (error) {
        console.error('Error downloading file:', error);
        alert('Ошибка при скачивании файла!');
      } finally {
        this.loading = false;
      }
    },
  },

  watch: {
    $regionAndDepartmentInfo: {
      async handler() {
        this.apiLoaded = false
        await this.initAnkets()
        this.apiLoaded = true
      },
      deep: true
    }
  },

  async beforeMount() {
    if(this.$route.query.configId){
      this.anketConfigIdAdjusted = this.anketConfigId = +this.$route.query.configId
    }
    this.beginDateAdjusted = this.beginDate = this.$route.query.beginDate
    this.endDateAdjusted = this.endDate = this.$route.query.endDate

    await this.loadAnketConfigs()
    // TODO в текущей реализации может стрелять 2 раза: здесь и сразу в вотч
    await this.initAnkets()
    this.apiLoaded = true
  },
};
</script>

<style lang="scss">
@import "../../styles/main.scss";
</style>

<style lang="scss" scoped>
.czn-link {
  margin-top: 16px;
  margin-bottom: 35px;
  font-size: 16px;

  div {
    font-style: normal;
    font-weight: 600;
    line-height: 160%;
    color: #1C1C1F;
    //margin-bottom: 5px;
  }

  table {
    width: 80%;
    margin-left: 35px;

    td {
      text-align: left;
      vertical-align: middle;
      padding: 5px;

      &:last-child {
        padding-right: 0;
        width: 40px;
      }

      &:nth-child(2) {
        text-align: right;
      }
    }
  }

  .v-btn {
    margin-left: 8px;
    max-height: 30px;
    min-width: 30px;
    padding: 0 2px;
  }
}
</style>
